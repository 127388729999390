* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.checkout {
  padding-top: 10px;
}

.checkout-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-price {
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 10px;
  margin: 0;
  color: rgba(0, 0, 0, 1);
}

.checkout-description {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-product-image {
  width: 400px;
}

.checkout-button {
  background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2);
  border-radius: 20px;

  display: flex;

  padding: 4px;
  width: 169px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-button:focus {
  outline: none;
}

.checkout-button:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1),
    0px 30px 60px rgba(23, 0, 102, 0.3);
  transform: translateY(-2px);
}

.checkout-button .grey-circle {
  background: rgba(68, 66, 178, 0.1);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .purple-circle {
  background: linear-gradient(200.44deg, #4316db 13.57%, #9076e7 98.38%);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .icon {
  width: 20px;
  height: 20px;
  margin: 0px;
}

.checkout-button .text-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  margin: auto;
  text-align: left;
  margin-left: 16px;
}

.checkout-button .text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  margin: 0;
  color: rgba(0, 0, 0, 1);
  margin: 0;
}
